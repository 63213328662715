import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {

  static targets = ["modalContainer", "modalTrigger", "modalCloseButton"]

  openModal() {
    this.modalContainerTarget.classList.remove("hidden")
  }

  closeModal(){
    this.modalContainerTarget.classList.add("hidden")    
  }

}
