import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["activityType", "newRecord", "writingTime", "readingTime", "activityTime", "peoplePerRoom"]

  connect() {
    this.displaySettings()
  }

  displaySettings() {
    this.clearInputs()

    switch(this.activityTypeTarget.value.toLowerCase().split(' ').join('_')) {
      case "share":
        this.newRecordTarget.insertAdjacentHTML('afterbegin', this.readingTimeTarget.innerHTML)
        this.newRecordTarget.insertAdjacentHTML('afterbegin', this.writingTimeTarget.innerHTML)
        break
      case "breakout":
        this.newRecordTarget.insertAdjacentHTML('afterbegin', this.activityTimeTarget.innerHTML)
        this.newRecordTarget.insertAdjacentHTML('afterbegin', this.peoplePerRoomTarget.innerHTML)
        break
      case "reflect":
        this.newRecordTarget.insertAdjacentHTML('afterbegin', this.activityTimeTarget.innerHTML)
        break
      case "take_action":
        this.newRecordTarget.insertAdjacentHTML('afterbegin', this.activityTimeTarget.innerHTML)
        break;
    }
  }

  clearInputs() {
    const writingTime = document.getElementById("writing-time")
    if (writingTime) { writingTime.remove() }

    const readingTime = document.getElementById("reading-time")
    if (readingTime) { readingTime.remove() }

    const activityTime = document.getElementById("activity-time")
    if (activityTime) { activityTime.remove() }

    const peoplePerRoom = document.getElementById("people-per-room")
    if (peoplePerRoom) { peoplePerRoom.remove() }
  }
}
